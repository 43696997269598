import React, { useContext, useEffect } from 'react';
import { useMyContext } from './StateHolder';
import { useSelector } from 'react-redux';
import { selectIsAuthenticated } from '../ducks/auth.duck';

// First create the context to hold shared functions
const MyContextFunctions = React.createContext();

// Then create a ContextFunctions wrapper component
// to hold the shared functions that the components need.

const ContextFunctions = props => {
  // Bring stateholders from another context
  const { setOpenAuthModal, setAuthType } = useMyContext();
  const isAuthenticated = useSelector(selectIsAuthenticated);

  const onOpenAuthModal = bool => {
    setOpenAuthModal(bool);
    if (!bool) {
      setAuthType('signup');
    }
  };

  const onSetAuthType = type => {
    setAuthType(type);
  };

  useEffect(() => {
    if (isAuthenticated) {
      onSetAuthType(false);
    }
  }, [isAuthenticated]);

  return (
    <MyContextFunctions.Provider
      value={{
        onOpenAuthModal,
        onSetAuthType,
      }}
    >
      {props.children}
    </MyContextFunctions.Provider>
  );
};

export const useMyContextFunctions = () => useContext(MyContextFunctions);

export default ContextFunctions;
