import React, { useState } from 'react';
import NamedLink from '../../NamedLink/NamedLink';
import Icons from '../../Icons/Icons';
import { isArrayLength } from '../../../util/genericHelpers';

import {
  FLUTES,
  INSTRUMENTS,
  NAVBAR_OPTIONS,
  NAVBAR_OPTIONS_INSTRUMENTS,
  WINEANDREED,
} from '../../../util/enums';
import { CATEGORIES } from '../../../config/configGeneric';

import categoryImg from '../../../assets/1.png';

import css from './MobileNavbar.module.css';
import classNames from 'classnames';

const MobileNavbar = props => {
  const [category, setCategory] = useState(null);
  const [subCategory, setSubCategory] = useState(null);
  const [subsubCategory, setSubSubCategory] = useState(null);

  const subcategories = CATEGORIES.find(category => category?.key === subCategory)?.subCategories;
  const subsubCategories =
    isArrayLength(subcategories) &&
    subcategories.find(sc => sc?.key === subsubCategory)?.subSubCategories;
  return (
    <div className={css.megaDropdownMenu}>
      {!category &&
        NAVBAR_OPTIONS?.map(nav => (
          <button className={css.megaDropdownMenuLink} onClick={() => setCategory(nav?.key)}>
            {nav?.label} {!nav?.hideArrow ? <Icons name="rightAngle" /> : null}
          </button>
        ))}

      {category === INSTRUMENTS && !subCategory && (
        <>
          {category ? (
            <div className={css.backLink} onClick={() => setCategory(null)}>
              <Icons name="back" /> {category}
            </div>
          ) : null}
          {NAVBAR_OPTIONS_INSTRUMENTS.map((option, i) => (
            <div
              className={css.megaDropdownMenuLink}
              key={i}
              onClick={() => setSubCategory(option.key)}
            >
              {option?.label}
            </div>
          ))}
        </>
      )}
      <div className={css.subCategoriesWrapper}>
        {!subsubCategory && (
          <>
            {subCategory ? (
              <div className={css.backLink} onClick={() => setSubCategory(null)}>
                <Icons name="back" /> {subCategory}
              </div>
            ) : null}
            {isArrayLength(subcategories) &&
              subcategories.map((sc, i) => (
                <div
                  className={css.megaDropdownMenuLink}
                  onClick={() => setSubSubCategory(sc.key)}
                  key={i}
                >
                  {sc?.label}
                  <Icons name="rightAngle" />
                </div>
              ))}
          </>
        )}
        <div className={css.verticalNavbarContent}>
          <div className={css.subcategories}>
            {subsubCategory ? (
              <div className={css.backLink} onClick={() => setSubSubCategory(null)}>
                <Icons name="back" /> {subsubCategory}
              </div>
            ) : null}
            {isArrayLength(subsubCategories) &&
              subsubCategories.map((ssc, i) => (
                <NamedLink className={css.megaDropdownMenuLink} name="SearchPage" key={i}>
                  {ssc?.label}
                </NamedLink>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MobileNavbar;
