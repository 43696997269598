import React, { useState, useContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';

// First create the context
const MyContext = React.createContext();

// Then create a StateHolder wrapper component
// to hold the state that the components need.

const StateHolder = props => {
  const [cartItems, setCartItems] = useState([]);
  const [openAuthModal, setOpenAuthModal] = useState(false);
  const [authType, setAuthType] = useState('signup');

  return (
    <MyContext.Provider
      value={{
        cartItems,
        setCartItems,
        openAuthModal,
        setOpenAuthModal,
        authType,
        setAuthType,
      }}
    >
      {props.children}
    </MyContext.Provider>
  );
};

export const useMyContext = () => useContext(MyContext);

export default StateHolder;
