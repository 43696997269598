import React from 'react';
import Icons from '../Icons/Icons';
import NamedLink from '../NamedLink/NamedLink';

import css from './Topbar.module.css';
import { useMyContextFunctions } from '../../context/ContextFunctions';
import { useMyContext } from '../../context/StateHolder';
import { useSelector } from 'react-redux';
import { selectIsAuthenticated } from '../../ducks/auth.duck';

function SectionTopbarNote(props) {
  const { intl, viewport } = props;
  const { onOpenAuthModal } = useMyContextFunctions();
  const { openAuthModal } = useMyContext();
  const isAuthenticated = useSelector(selectIsAuthenticated);

  const sellLabel = intl.formatMessage({ id: 'SectionTopbarNote.sellLabel' });
  const listItLabel = intl.formatMessage({ id: 'SectionTopbarNote.listItLabel' });
  const isMobileLayout = viewport.width > 0 && viewport.width < 768;
  return (
    <>
      {isMobileLayout ? (
        <div className={css.topbarTop}>
          <div className={css.fixedWidthContainer}>
            <p>
              {sellLabel}{' '}
              {isAuthenticated ? (
                <NamedLink name="NewListingPage">{listItLabel}</NamedLink>
              ) : (
                <span onClick={() => onOpenAuthModal(!openAuthModal)}>{listItLabel}</span>
              )}
            </p>
            <div className={css.countryCurrency}>
              <div className={css.languageSelect}>
                <span className={css.flag}>
                  <img
                    src="https://img.freepik.com/free-vector/illustration-uk-flag_53876-18166.jpg?size=626&ext=jpg&ga=GA1.1.44546679.1699228800&semt=ais"
                    alt=""
                  />
                </span>
                English <Icons name="downAngle" />
              </div>
              <div className={css.currencySelect}>
                USD <Icons name="downAngle" />
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className={css.topbarTop}>
          <div className={css.fixedWidthContainer}>
            <p>
              {sellLabel}{' '}
              {isAuthenticated ? (
                <NamedLink name="NewListingPage">{listItLabel}</NamedLink>
              ) : (
                <span onClick={() => onOpenAuthModal(!openAuthModal)}>{listItLabel}</span>
              )}
            </p>
            <div className={css.countryCurrency}>
              <div className={css.languageSelect}>
                <span className={css.flag}>
                  <img
                    src="https://img.freepik.com/free-vector/illustration-uk-flag_53876-18166.jpg?size=626&ext=jpg&ga=GA1.1.44546679.1699228800&semt=ais"
                    alt=""
                  />
                </span>
                English <Icons name="downAngle" />
              </div>
              <div className={css.currencySelect}>
                USD <Icons name="downAngle" />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default SectionTopbarNote;
