export const getUserCart = currentUser => {
  return (
    (currentUser && currentUser.id && currentUser?.attributes?.profile?.publicData?.cart) || []
  );
};

export const isStripeConnected = currentUser => {
  return (
    currentUser && currentUser.id && currentUser?.attributes?.profile?.publicData?.isStripeConnected
  );
};

export const isPaypalConnected = currentUser => {
  if (!currentUser && !currentUser?.id) return null;
  return (
    currentUser && currentUser.id && currentUser?.attributes?.profile?.publicData?.isPaypalConnected
  );
};

export const getShippingDetails = currentUser => {
  return (
    currentUser &&
    currentUser.id &&
    currentUser?.attributes?.profile?.protectedData?.shippingDetails
  );
};

export const getUserEmail = currentUser => currentUser?.attributes?.email || null;
export const getDisplayName = currentUser => currentUser?.attributes?.profile?.displayName || '';

export function getUserCartDetails(user) {
  // Consolidated null/undefined checks for robustness:
  if (!user || !user.id) {
    // Handle missing user or user ID gracefully
    return null;
  }

  // Combine optional chaining with default value for concise and safe access:
  const protectedData = user.attributes?.profile?.protectedData || {};
  return protectedData.cartItems ?? [];
}

export const getUserShippingAddress = user => {
  if (!user && !user?.id) return null;
  const { shippingAddress = {} } = user?.attributes?.profile?.privateData || {};
  return shippingAddress;
};

export const getUserDetails = user => {
  if (!user || !user.id) return null;

  const { firstName, lastName, displayName } = user.attributes.profile || {};

  // Combine firstName and lastName to create fullName
  const fullName = `${firstName || ''} ${lastName || ''}`.trim();

  return { fullName, displayName };
};

export const getShippingAddressID = user => {
  return user?.attributes?.profile?.publicData?.shippingAddressId;
};

/**
 * Returns the title of the listing associated with a transaction.
 * @param {object} tx - The transaction object.
 * @returns {string|null} The listing title, or null if input is invalid or listing title is missing.
 */
export const getTxTitle = tx => {
  const currentListing = tx?.listing;
  const title = currentListing && currentListing.attributes && currentListing.attributes.title;
  return title;
};

export const getStoreNameOrDisplayName = author => {
  const displayName = getDisplayName(author);
  return displayName;
};

/**
 * Checks if a transaction is a shipping transaction.
 *
 * @param {Object} tx - The transaction object.
 * @returns {number} - The offer price of the transaction
 */
export const getOfferPrice = tx => {
  return tx && tx.id && tx?.attributes?.protectedData?.offerParams?.offerPrice;
};

export const getOfferParams = tx => {
  return tx && tx.id && tx?.attributes?.protectedData?.offerParams;
};

export const isOwnOffer = (customer, currentUser) => {
  return customer?.id?.uuid === currentUser?.id?.uuid;
};

/**
 * Returns the URL of the image associated with a transaction's listing.
 * @param {object} tx - The transaction object.
 * @returns {string} The URL of the image, or a default image URL if input is invalid or image is missing.
 */
export const getTxImage = tx => {
  const currentListing = tx?.listing;
  const firstImage =
    currentListing && currentListing.images && currentListing.images.length > 0
      ? currentListing.images[0]
      : null;

  const sharetribeImage =
    firstImage &&
    firstImage.attributes &&
    firstImage.attributes.variants &&
    firstImage.attributes.variants['square-small2x'] &&
    firstImage.attributes.variants['square-small2x'].url;

  return sharetribeImage;
};

export const getSimilarParams = (listing, currentUser) => {
  const publicData = listing?.attributes?.publicData;
  return {
    brand: publicData?.brand,
    size: publicData?.size,
  };
};

export const doesAcceptOffer = listing => {
  return listing?.attributes?.publicData?.acceptOffers;
};

export const getListingShippingParams = (listing, isDomestic) => {
  const publicData = listing?.attributes?.publicData;
  return isDomestic ? publicData?.domesticParams : publicData?.internationalParams;
};

export const isDomesticShipping = (author, currentUser, customerCountryMaybe) => {
  const authorCountry = author?.attributes?.profile?.publicData?.country;
  const currentUserCountry =
    customerCountryMaybe || currentUser?.attributes?.profile?.privateData?.shippingAddress?.country;

  return authorCountry === currentUserCountry;
};
