import { ENUM_NAVBAR_ITEMS, FLUTES } from '../util/enums';

export const CATEGORIES = [
  {
    key: 'wine-and-reed',
    label: 'Wind & Reed',
    subCategories: [
      {
        key: 'flutes',
        label: 'Flutes',
        subSubCategories: [
          {
            key: 'all-flutes',
            label: 'All Flutes',
          },
          {
            key: 'irish-flutes',
            label: 'Irish Flutes',
          },
          {
            key: 'antique-vintage-flutes',
            label: 'Antique & Vintage Flutes',
          },
          {
            key: 'boehm-silver-flutes',
            label: 'Boehm / Silver Flutes',
          },
          {
            key: 'folk-world-flutes',
            label: 'Folk & World Flutes',
          },
          {
            key: 'piccolos',
            label: 'Piccolos',
          },
          {
            key: 'fifes',
            label: 'Fifes',
          },
          {
            key: 'flute-accessories',
            label: 'Flute Accessories',
          },
          {
            key: 'flutes-books-media',
            label: 'Flutes Books & Media',
          },
        ],
      },
      {
        key: 'tin-whistles',
        label: 'Tin Whistles',
        subSubCategories: [
          {
            key: 'all-flutes',
            label: 'All Flutes',
          },
          {
            key: 'irish-flutes',
            label: 'Irish Flutes',
          },
          {
            key: 'antique-vintage-flutes',
            label: 'Antique & Vintage Flutes',
          },
          {
            key: 'boehm-silver-flutes',
            label: 'Boehm / Silver Flutes',
          },
          {
            key: 'folk-world-flutes',
            label: 'Folk & World Flutes',
          },
          {
            key: 'piccolos',
            label: 'Piccolos',
          },
          {
            key: 'fifes',
            label: 'Fifes',
          },
          {
            key: 'flute-accessories',
            label: 'Flute Accessories',
          },
          {
            key: 'flutes-books-media',
            label: 'Flutes Books & Media',
          },
        ],
      },
      {
        key: 'recorders',
        label: 'Recorders',
        subSubCategories: [
          {
            key: 'all-flutes',
            label: 'All Flutes',
          },
          {
            key: 'irish-flutes',
            label: 'Irish Flutes',
          },
          {
            key: 'antique-vintage-flutes',
            label: 'Antique & Vintage Flutes',
          },
          {
            key: 'boehm-silver-flutes',
            label: 'Boehm / Silver Flutes',
          },
          {
            key: 'folk-world-flutes',
            label: 'Folk & World Flutes',
          },
          {
            key: 'piccolos',
            label: 'Piccolos',
          },
          {
            key: 'fifes',
            label: 'Fifes',
          },
          {
            key: 'flute-accessories',
            label: 'Flute Accessories',
          },
          {
            key: 'flutes-books-media',
            label: 'Flutes Books & Media',
          },
        ],
      },
      {
        key: 'bagpipes',
        label: 'Bagpipes',
        subSubCategories: [
          {
            key: 'all-flutes',
            label: 'All Flutes',
          },
          {
            key: 'irish-flutes',
            label: 'Irish Flutes',
          },
          {
            key: 'antique-vintage-flutes',
            label: 'Antique & Vintage Flutes',
          },
          {
            key: 'boehm-silver-flutes',
            label: 'Boehm / Silver Flutes',
          },
          {
            key: 'folk-world-flutes',
            label: 'Folk & World Flutes',
          },
          {
            key: 'piccolos',
            label: 'Piccolos',
          },
          {
            key: 'fifes',
            label: 'Fifes',
          },
          {
            key: 'flute-accessories',
            label: 'Flute Accessories',
          },
          {
            key: 'flutes-books-media',
            label: 'Flutes Books & Media',
          },
        ],
      },
      {
        key: 'accordions',
        label: 'Accordions',
        subSubCategories: [
          {
            key: 'all-flutes',
            label: 'All Flutes',
          },
          {
            key: 'irish-flutes',
            label: 'Irish Flutes',
          },
          {
            key: 'antique-vintage-flutes',
            label: 'Antique & Vintage Flutes',
          },
          {
            key: 'boehm-silver-flutes',
            label: 'Boehm / Silver Flutes',
          },
          {
            key: 'folk-world-flutes',
            label: 'Folk & World Flutes',
          },
          {
            key: 'piccolos',
            label: 'Piccolos',
          },
          {
            key: 'fifes',
            label: 'Fifes',
          },
          {
            key: 'flute-accessories',
            label: 'Flute Accessories',
          },
          {
            key: 'flutes-books-media',
            label: 'Flutes Books & Media',
          },
        ],
      },
      {
        key: 'concertinas',
        label: 'Concertinas',
        subSubCategories: [
          {
            key: 'all-flutes',
            label: 'All Flutes',
          },
          {
            key: 'irish-flutes',
            label: 'Irish Flutes',
          },
          {
            key: 'antique-vintage-flutes',
            label: 'Antique & Vintage Flutes',
          },
          {
            key: 'boehm-silver-flutes',
            label: 'Boehm / Silver Flutes',
          },
          {
            key: 'folk-world-flutes',
            label: 'Folk & World Flutes',
          },
          {
            key: 'piccolos',
            label: 'Piccolos',
          },
          {
            key: 'fifes',
            label: 'Fifes',
          },
          {
            key: 'flute-accessories',
            label: 'Flute Accessories',
          },
          {
            key: 'flutes-books-media',
            label: 'Flutes Books & Media',
          },
        ],
      },
      {
        key: 'harmonicas',
        label: 'Harmonicas',
        subSubCategories: [
          {
            key: 'all-flutes',
            label: 'All Flutes',
          },
          {
            key: 'irish-flutes',
            label: 'Irish Flutes',
          },
          {
            key: 'antique-vintage-flutes',
            label: 'Antique & Vintage Flutes',
          },
          {
            key: 'boehm-silver-flutes',
            label: 'Boehm / Silver Flutes',
          },
          {
            key: 'folk-world-flutes',
            label: 'Folk & World Flutes',
          },
          {
            key: 'piccolos',
            label: 'Piccolos',
          },
          {
            key: 'fifes',
            label: 'Fifes',
          },
          {
            key: 'flute-accessories',
            label: 'Flute Accessories',
          },
          {
            key: 'flutes-books-media',
            label: 'Flutes Books & Media',
          },
        ],
      },
    ],
  },
  {
    key: 'strings',
    label: 'Strings',
    subCategories: [
      {
        name: 'Guitars',
        // ... other sub-sub categories
      },
      {
        name: 'Bouzoukis',
        // ... other sub-sub categories
      },
      // ... other sub-categories of Strings
    ],
  },
  {
    key: 'percussion',
    label: 'Percussion',
    subCategories: [
      {
        name: 'Bodhrans',
        // ... other sub-sub categories
      },
      {
        name: 'Bones',
        // ... other sub-sub categories
      },
      // ... other sub-categories of Percussion
    ],
  },
  // ... other major categories
];

export const subsubCategoryOptions = [
  {
    value: 'flutes',
    label: 'Flutes',
    parent: ENUM_NAVBAR_ITEMS.WINEANDREED,
  },
  {
    value: 'tin-whistles',
    label: 'Tin Whistles',
    parent: ENUM_NAVBAR_ITEMS.WINEANDREED,
  },
  {
    value: 'recorders',
    label: 'Recorders',
    parent: ENUM_NAVBAR_ITEMS.WINEANDREED,
  },
  {
    value: 'bagpipes',
    label: 'Bagpipes',
    parent: ENUM_NAVBAR_ITEMS.WINEANDREED,
  },
  {
    value: 'accordions',
    label: 'Accordions',
    parent: ENUM_NAVBAR_ITEMS.WINEANDREED,
  },
  {
    value: 'concertinas',
    label: 'Concertinas',
    parent: ENUM_NAVBAR_ITEMS.WINEANDREED,
  },
  {
    value: 'harmonicas',
    label: 'Harmonicas',
    parent: ENUM_NAVBAR_ITEMS.WINEANDREED,
  },
  {
    value: 'guitars',
    label: 'Guitars',
    parent: ENUM_NAVBAR_ITEMS.STRINGS,
  },
  {
    value: 'bouzoukis',
    label: 'Bouzoukis',
    parent: ENUM_NAVBAR_ITEMS.STRINGS,
  },
  {
    value: 'fiddles',
    label: 'Fiddles',
    parent: ENUM_NAVBAR_ITEMS.STRINGS,
  },
  {
    value: 'harps',
    label: 'Harps',
    parent: ENUM_NAVBAR_ITEMS.STRINGS,
  },
  {
    value: 'mandolins',
    label: 'Mandolins',
    parent: ENUM_NAVBAR_ITEMS.STRINGS,
  },
  {
    value: 'banjos',
    label: 'Banjos',
    parent: ENUM_NAVBAR_ITEMS.STRINGS,
  },
  {
    value: 'bodhrans',
    label: 'Bodhrans',
    parent: ENUM_NAVBAR_ITEMS.PRECUSSION,
  },
  {
    value: 'bones',
    label: 'Bones',
    parent: ENUM_NAVBAR_ITEMS.PRECUSSION,
  },
  {
    value: 'spoons',
    label: 'Spoons',
    parent: ENUM_NAVBAR_ITEMS.PRECUSSION,
  },
];

export const typeOptions = [
  {
    value: 'antique-vintage',
    label: 'Antique & Vintage',
    parent: FLUTES,
  },
  {
    value: 'boehm',
    label: 'Boehm',
    parent: FLUTES,
  },
  {
    value: 'dizi',
    label: 'Dizi',
    parent: FLUTES,
  },
  {
    value: 'fife',
    label: 'Fife',
    parent: FLUTES,
  },
  {
    value: 'folk',
    label: 'Folk',
    parent: FLUTES,
  },
  {
    value: 'indian',
    label: 'Indian',
    parent: FLUTES,
  },
  {
    value: 'irish',
    label: 'Irish',
    parent: FLUTES,
  },
  {
    value: 'native-american',
    label: 'Native American',
    parent: FLUTES,
  },
  {
    value: 'pan',
    label: 'Pan',
    parent: FLUTES,
  },
  {
    value: 'piccolo',
    label: 'Piccolo',
    parent: FLUTES,
  },
  {
    value: 'qantati',
    label: 'Qantati',
    parent: FLUTES,
  },
  {
    value: 'quena',
    label: 'Quena',
    parent: FLUTES,
  },
  {
    value: 'quinacho',
    label: 'Quinacho',
    parent: FLUTES,
  },
  {
    value: 'shakuhachi',
    label: 'Shakuhachi',
    parent: FLUTES,
  },
  {
    value: 'siccama',
    label: 'Siccama',
    parent: FLUTES,
  },
  {
    value: 'world',
    label: 'World',
    parent: FLUTES,
  },
];

export const conditionOptions = [
  {
    key: 'new',
    heading: 'New',
    description: 'Never used other than for a quick demo. If packaged, never opened.',
  },
  {
    key: 'excellent',
    heading: 'Excellent',
    description:
      'Mint to near-mint. No initially noticeable marks, scratches, cracks or any other defects.',
  },
  {
    key: 'good',
    heading: 'Good',
    description:
      'Light markings from being well loved. Repairs (if any) must be near invisible and described below.',
  },
  {
    key: 'fair',
    heading: 'Fair',
    description: 'Still fully functioning but well worn from countless sessions and/or gigs!',
  },
  {
    key: 'poor',
    heading: 'Poor',
    description: 'Has seen better days but someone might be willing to use or restore.',
  },
];

export const shippingRateOptions = [
  {
    key: '',
    label: 'Please select a shipping rate',
    disabled: true,
  },
  {
    key: 'flat-rate',
    label: 'Flat rate: Same cost regardless of buyer location',
  },
  {
    key: 'calculated',
    label: 'Calculated: Cost varies based on buyer location',
  },
];
