import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';
import { FormattedMessage, injectIntl, intlShape } from '../../../util/reactIntl';
import * as validators from '../../../util/validators';
import { Form, PrimaryButton, FieldTextInput, NamedLink } from '../../../components';
import { useMyContextFunctions } from '../../../context/ContextFunctions';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { createResourceLocatorString } from '../../../util/routes';
import routeConfiguration from '../../../routing/routeConfiguration';
import { SIGNUP } from '../../../util/enums';

import css from './LoginForm.module.css';

const LoginFormComponent = props => (
  <FinalForm
    {...props}
    render={fieldRenderProps => {
      const {
        rootClassName,
        className,
        formId,
        handleSubmit,
        inProgress,
        intl,
        invalid,
        renderLoginOrSignupError,
      } = fieldRenderProps;

      const history = useHistory();
      const { onSetAuthType, onOpenAuthModal } = useMyContextFunctions();

      // email
      const emailLabel = intl.formatMessage({
        id: 'LoginForm.emailLabel',
      });
      const emailPlaceholder = intl.formatMessage({
        id: 'LoginForm.emailPlaceholder',
      });
      const emailRequiredMessage = intl.formatMessage({
        id: 'LoginForm.emailRequired',
      });
      const emailRequired = validators.required(emailRequiredMessage);
      const emailInvalidMessage = intl.formatMessage({
        id: 'LoginForm.emailInvalid',
      });
      const emailValid = validators.emailFormatValid(emailInvalidMessage);

      // password
      const passwordLabel = intl.formatMessage({
        id: 'LoginForm.passwordLabel',
      });
      const passwordPlaceholder = intl.formatMessage({
        id: 'LoginForm.passwordPlaceholder',
      });
      const passwordRequiredMessage = intl.formatMessage({
        id: 'LoginForm.passwordRequired',
      });
      const passwordRequired = validators.requiredStringNoTrim(passwordRequiredMessage);

      const classes = classNames(rootClassName || css.root, className);
      const submitInProgress = inProgress;
      const submitDisabled = invalid || submitInProgress;

      const onRedirectToPage = page => {
        onOpenAuthModal(false);
        history.push(createResourceLocatorString(page, routeConfiguration(), {}, {}));
      };

      const passwordRecoveryLink = (
        <span onClick={() => onRedirectToPage('PasswordRecoveryPage')} className={css.recoveryLink}>
          <FormattedMessage id="LoginForm.forgotPassword" />
        </span>
      );

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          <div className={css.loginContent}>
            <h2>
              {' '}
              <FormattedMessage id="LoginForm.loginTitle" />
            </h2>
            <FieldTextInput
              type="email"
              id={formId ? `${formId}.email` : 'email'}
              name="email"
              autoComplete="email"
              label={emailLabel}
              placeholder={emailPlaceholder}
              validate={validators.composeValidators(emailRequired, emailValid)}
            />
            <FieldTextInput
              className={css.password}
              type="password"
              id={formId ? `${formId}.password` : 'password'}
              name="password"
              autoComplete="current-password"
              label={passwordLabel}
              placeholder={passwordPlaceholder}
              validate={passwordRequired}
            />
            <div className={css.errorWrapper}>{renderLoginOrSignupError}</div>
            <div className={css.bottomWrapper}>
              <PrimaryButton type="submit" inProgress={submitInProgress} disabled={submitDisabled}>
                <FormattedMessage id="LoginForm.logIn" />
              </PrimaryButton>
              {passwordRecoveryLink}
              <span className={css.recoveryLink} onClick={() => onSetAuthType(SIGNUP)}>
                <FormattedMessage id="LoginForm.createAnAccount" />
              </span>
            </div>
          </div>
          <p className={css.termsLink}>
            <FormattedMessage id="LoginForm.agreeTo" />{' '}
            <span onClick={() => onRedirectToPage('TermsOfServicePage')}>
              <FormattedMessage id="LoginForm.tos" />
            </span>
          </p>
        </Form>
      );
    }}
  />
);

LoginFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  form: null,
  inProgress: false,
};

const { string, bool } = PropTypes;

LoginFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  form: string,
  inProgress: bool,
  intl: intlShape.isRequired,
};

const LoginForm = compose(injectIntl)(LoginFormComponent);
LoginForm.displayName = 'LoginForm';

export default LoginForm;
