import React, { useState, useEffect } from 'react';
import { bool, func, object, number, string } from 'prop-types';
import classNames from 'classnames';

import { FormattedMessage, intlShape } from '../../../util/reactIntl';
import routeConfiguration, { ACCOUNT_SETTINGS_PAGES } from '../../../routing/routeConfiguration';
import { propTypes } from '../../../util/types';
import {
  Avatar,
  InlineTextButton,
  LinkedLogo,
  Menu,
  MenuLabel,
  MenuContent,
  MenuItem,
  NamedLink,
  Icons,
  IconSearch,
} from '../../../components';
import { createResourceLocatorString } from '../../../util/routes';
import Navbar from '../Navbar/Navbar';
import TopbarSearchForm from '../TopbarSearchForm/TopbarSearchForm';
import {
  ACCESSORIES,
  BOOKSANDMEDIA,
  ENUM_NAVBAR_ITEMS,
  LOGIN,
  NAVBAR_OPTIONS,
} from '../../../util/enums';

import css from './TopbarDesktop.module.css';
import { useMyContextFunctions } from '../../../context/ContextFunctions';
import { useMyContext } from '../../../context/StateHolder';

const TopbarDesktop = props => {
  const {
    className,
    appConfig,
    currentUser,
    currentPage,
    rootClassName,
    currentUserHasListings,
    notificationCount,
    intl,
    isAuthenticated,
    onLogout,
    onSearchSubmit,
    initialSearchFormValues,
    history,
    updatedCartCount,
  } = props;

  const [mounted, setMounted] = useState(false);
  const [showSearchOverlay, setSearchOverlay] = useState(false);
  const [selectedNavItem, setSelectedNavItem] = useState(null);
  const { onOpenAuthModal, onSetAuthType } = useMyContextFunctions();
  const { openAuthModal } = useMyContext();

  useEffect(() => {
    setMounted(true);
  }, []);

  const marketplaceName = appConfig.marketplaceName;
  const authenticatedOnClientSide = mounted && isAuthenticated;
  const isAuthenticatedOrJustHydrated = isAuthenticated || !mounted;

  const classes = classNames(rootClassName || css.root, className);

  const search = (
    <TopbarSearchForm
      className={css.searchLink}
      desktopInputRoot={css.topbarSearchWithLeftPadding}
      onSubmit={onSearchSubmit}
      initialValues={initialSearchFormValues}
      appConfig={appConfig}
    />
  );

  const notificationDot = notificationCount > 0 ? <div className={css.notificationDot} /> : null;

  const inboxLink = authenticatedOnClientSide ? (
    <NamedLink
      className={css.navLink}
      name="InboxPage"
      params={{ tab: currentUserHasListings ? 'sales' : 'orders' }}
    >
      <span className={css.inbox}>
        <FormattedMessage id="TopbarDesktop.inbox" />
        {notificationDot}
      </span>
    </NamedLink>
  ) : null;

  const currentPageClass = page => {
    const isAccountSettingsPage =
      page === 'AccountSettingsPage' && ACCOUNT_SETTINGS_PAGES.includes(currentPage);
    return currentPage === page || isAccountSettingsPage ? css.currentPage : null;
  };

  const profileMenu = authenticatedOnClientSide ? (
    <Menu contentPosition="left">
      <MenuLabel className={css.profileMenuLabel} isOpenClassName={css.profileMenuIsOpen}>
        <Avatar className={css.avatar} user={currentUser} disableProfileLink />
      </MenuLabel>
      <MenuContent className={css.profileMenuContent}>
        <MenuItem key="ManageListingsPage">
          <NamedLink
            className={classNames(css.yourListingsLink, currentPageClass('ManageListingsPage'))}
            name="ManageListingsPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.yourListingsLink" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="OffersPage">
          <NamedLink
            className={classNames(css.yourListingsLink, currentPageClass('OffersPage'))}
            name="OffersPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.yourOffers" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="ProfileSettingsPage">
          <NamedLink
            className={classNames(css.profileSettingsLink, currentPageClass('ProfileSettingsPage'))}
            name="ProfileSettingsPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.profileSettingsLink" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="AccountSettingsPage">
          <NamedLink
            className={classNames(css.yourListingsLink, currentPageClass('AccountSettingsPage'))}
            name="AccountSettingsPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.accountSettingsLink" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="logout">
          <InlineTextButton rootClassName={css.logoutButton} onClick={onLogout}>
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.logout" />
          </InlineTextButton>
        </MenuItem>
      </MenuContent>
    </Menu>
  ) : null;

  const signupLink = isAuthenticatedOrJustHydrated ? null : (
    <span
      onClick={() => {
        onOpenAuthModal(!openAuthModal);
        onSetAuthType(LOGIN);
      }}
      className={classNames(css.commonIcons, css.searchIcon)}
    >
      <Icons name="user" />
    </span>
  );

  const renderLogo = (
    <LinkedLogo
      className={css.logoLink}
      layout="desktop"
      alt={intl.formatMessage({ id: 'TopbarDesktop.logo' }, { marketplaceName })}
    />
  );

  const redirectToPage = (name, params = {}, search = {}) => {
    history.push(createResourceLocatorString(name, routeConfiguration(), params, search));
  };

  const renderNavBarWithoutOverlay = (
    <div onMouseLeave={() => selectedNavItem && setSelectedNavItem(null)}>
      <nav className={classes}>
        <div className={css.fixedWidthContainer}>
          {renderLogo}
          <div className={css.middleLinks}>
            {NAVBAR_OPTIONS.map((item, index) => (
              <span
                key={index}
                className={css.navLink}
                onMouseEnter={() => {
                  if (![ACCESSORIES, BOOKSANDMEDIA].includes(item?.key)) {
                    !selectedNavItem && setSelectedNavItem(item.key);
                  } else {
                    setSelectedNavItem(null);
                  }
                }}
                onClick={() => {
                  if ([ACCESSORIES, BOOKSANDMEDIA].includes(item?.key)) {
                    redirectToPage('SearchPage', {}, { pub_category: item?.key });
                  }
                }}
              >
                {item?.label} {!item?.hideArrow ? <Icons name="downAngle" /> : null}
              </span>
            ))}
          </div>
          <div className={css.rightLinks}>
            {[
              { name: 'search', onClick: () => setSearchOverlay(true) },
              {
                name: 'unfavorite',
                onClick: () =>
                  isAuthenticated
                    ? redirectToPage('FavouritesPage')
                    : onOpenAuthModal(!openAuthModal),
              },
              ...(isAuthenticated
                ? [
                    {
                      name: 'email',
                      onClick: () =>
                        redirectToPage('InboxPage', {
                          tab: currentUserHasListings ? 'sales' : 'orders',
                        }),
                    },
                  ]
                : []),
              {
                name: 'cart',
                notification: updatedCartCount,
                onClick: () =>
                  isAuthenticated ? redirectToPage('CartPage') : onOpenAuthModal(!openAuthModal),
              },
            ].map((icon, index) => (
              <span
                key={index}
                onClick={icon?.onClick}
                className={classNames(css.commonIcons, css.searchIcon)}
              >
                <Icons name={icon?.name} />
                {icon?.notification && (
                  <span className={css.notification}>{icon?.notification}</span>
                )}
              </span>
            ))}
            {signupLink}
            {profileMenu}
          </div>
        </div>
        {selectedNavItem === ENUM_NAVBAR_ITEMS.INSTRUMENTS ? (
          <Navbar selectedNavItem={ENUM_NAVBAR_ITEMS.INSTRUMENTS} />
        ) : null}
      </nav>
    </div>
  );

  const renderNavBarWithOverlay = (
    <div
      className={css.searchDropdown}
      onMouseLeave={() => selectedNavItem && setSelectedNavItem(null)}
    >
      <nav className={classes}>
        <div className={css.fixedWidthContainer}>
          {renderLogo}
          <div className={css.rightLinks}>
            <span
              onClick={() => setSearchOverlay(false)}
              className={classNames(css.commonIcons, css.searchIcon)}
            >
              <Icons name="closeIcon" />
            </span>
          </div>
        </div>
      </nav>
      <div className={css.searchDropdownContentWrapper}>
        <div className={css.searchDropdownContent}>
          {search}
          <div className={css.quickSearch}>
            <span className={css.quickSearchLink}>Quick search : </span>
            <NamedLink className={css.quickSearchLink} name="SearchPage">
              Antique & Vintage Flutes
            </NamedLink>{' '}
            <NamedLink className={css.quickSearchLink} name="SearchPage">
              Folk & World Flutes
            </NamedLink>{' '}
            <NamedLink className={css.quickSearchLink} name="SearchPage">
              Flutes Books & Media
            </NamedLink>{' '}
            <NamedLink className={css.quickSearchLink} name="SearchPage">
              Boehm / Silver Flutes
            </NamedLink>
          </div>
        </div>
      </div>
    </div>
  );

  return showSearchOverlay ? renderNavBarWithOverlay : renderNavBarWithoutOverlay;
};

TopbarDesktop.defaultProps = {
  rootClassName: null,
  className: null,
  currentUser: null,
  currentPage: null,
  notificationCount: 0,
  initialSearchFormValues: {},
  appConfig: null,
};

TopbarDesktop.propTypes = {
  rootClassName: string,
  className: string,
  currentUserHasListings: bool.isRequired,
  currentUser: propTypes.currentUser,
  currentPage: string,
  isAuthenticated: bool.isRequired,
  onLogout: func.isRequired,
  notificationCount: number,
  onSearchSubmit: func.isRequired,
  initialSearchFormValues: object,
  intl: intlShape.isRequired,
  appConfig: object,
};

export default TopbarDesktop;
