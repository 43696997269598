import { getUserCartDetails } from '../../util/dataExtractors';
import { ADD } from '../../util/enums';
import { isArrayLength } from '../../util/genericHelpers';
import { deleteItemFromCart } from './CartPage.duck';

export const extractListingDataForCart = listing => {
  const currentStock = listing && listing.currentStock;
  const quantity = currentStock?.attributes?.quantity;

  const price = listing?.attributes?.price;
  const currency = price?.currency;

  const listingId = listing?.id?.uuid;
  const title = listing?.attributes?.title;
  const authorId = listing?.author?.id?.uuid;
  const publicData = listing?.attributes?.publicData;
  const shippingPriceInSubunitsOneItem = publicData?.shippingPriceInSubunitsOneItem;
  const shippingPriceInSubunitsAdditionalItems = publicData?.shippingPriceInSubunitsAdditionalItems;

  const firstImage =
    listing &&
    listing.images &&
    listing.images[0] &&
    listing.images[0].attributes &&
    listing.images[0].attributes.variants &&
    listing.images[0].attributes.variants['listing-card'] &&
    listing.images[0].attributes.variants['listing-card'].url;

  return {
    listingId,
    stockCount: 1,
    price,
    title,
    authorId,
    shippingPriceInSubunitsOneItem,
    shippingPriceInSubunitsAdditionalItems,
    currency,
    oldStock: quantity,
    firstImage,
  };
};

export const onToggleQuanity = params => {
  const {
    currentItem,
    currentCartItem,
    setCurrentItem,
    setStockDetails,
    stockDetails,
    type,
  } = params;
  const currentItemParams = {
    ...currentItem,
    stockCount: type === ADD ? currentItem?.stockCount + 1 : currentItem?.stockCount - 1,
  };

  stockDetails[currentCartItem.index] = currentItemParams;
  setCurrentItem(currentItemParams);
  setStockDetails(stockDetails);
};

export const removeItemFromCart = async params => {
  const { dispatch, ...rest } = params;
  try {
    const response = await dispatch(deleteItemFromCart(rest));
  } catch (error) {
    // showToaster('error', 'Error removing item from cart');
  }
};

export const getLocalCartItems = () => {
  return JSON.parse(typeof window !== 'undefined' && localStorage.getItem('localCartItems')) || [];
};
export const setLocalCartItems = data => {
  return (
    typeof window !== 'undefined' && localStorage.setItem('localCartItems', JSON.stringify(data))
  );
};
export const removeLocalCartItems = () => {
  return typeof window !== 'undefined' && localStorage.removeItem('localCartItems');
};

export const getAllCartItems = currentUser => {
  const cartItems = getUserCartDetails(currentUser);
  const localCartItems = getLocalCartItems();

  // Combine cart items from user data and local storage
  const allCartItems = [...(cartItems || []), ...(localCartItems || [])];

  // Filter out duplicates (assuming cart items have a unique identifier)
  const uniqueCartItems = new Set(allCartItems.map(item => item)); // Or any unique identifier property

  // Convert unique identifiers back to cart items
  const combinedCartItems = Array.from(uniqueCartItems).map(id => {
    // Find the corresponding item in the combined list
    const matchingItem = allCartItems.find(item => item === id);
    return matchingItem;
  });

  // Return the combined and deduplicated cart items
  return combinedCartItems;
};

export const cartItemsCheckoutData = params => {
  try {
  } catch (error) {}
};

export const isItemAddedToCart = params => {
  if (!Object.keys(params).length) return null;
  const { cartItems, listingId } = params;
  return isArrayLength(cartItems) && cartItems?.some(id => id === listingId);
};

export const getTotal = params => {
  const { listings, stockDetails } = params;
  if (isArrayLength(listings) && isArrayLength(stockDetails)) {
    const authorId = listings?.[0]?.author?.id?.uuid;
    let onlyGroupedStockDetails = stockDetails?.filter(s => s?.authorId === authorId);
    const updatedCartWithOffers = [];

    for (let index = 0; index < onlyGroupedStockDetails.length; index++) {
      const currentCart = onlyGroupedStockDetails[index];
      updatedCartWithOffers.push(currentCart);
    }
    onlyGroupedStockDetails = updatedCartWithOffers;

    const totalSum = onlyGroupedStockDetails?.reduce(
      (sum, l) => sum + (l?.price?.amount / 100) * l?.stockCount,
      0
    );
    const totalItems = onlyGroupedStockDetails?.reduce((sum, l) => sum + l?.stockCount, 0);
    return { price: totalSum?.toFixed(2), items: totalItems };
  }
};
