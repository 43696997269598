import React from 'react';
import Toggle from 'react-toggle';
import './ToggleWrapper.css';

const ToggleWrapper = ({
  checked,
  onChange,
  disabled = false,
  icons = false,
  id,
  name,
  ...props
}) => {
  return (
    <Toggle
      checked={checked}
      onChange={onChange}
      disabled={disabled}
      id={id}
      icons={icons}
      name={name}
      {...props}
    />
  );
};

export default ToggleWrapper;
