import React from 'react';
import { useHistory } from 'react-router-dom';
import { Icons } from '../../components';
import css from './BottomNav.module.css';
import { createResourceLocatorString } from '../../util/routes';
import routeConfiguration from '../../routing/routeConfiguration';
import { useMyContextFunctions } from '../../context/ContextFunctions';
import { useMyContext } from '../../context/StateHolder';
import { useSelector } from 'react-redux';
import { selectIsAuthenticated } from '../../ducks/auth.duck';

function BottomNav() {
  const history = useHistory();
  const { onOpenAuthModal } = useMyContextFunctions();
  const { openAuthModal, authType } = useMyContext();
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const onRedirectToPage = pageName => () => {
    history.push(createResourceLocatorString(pageName, routeConfiguration(), {}, {}));
  };

  return (
    <div className={css.bottomNavbar}>
      <span onClick={onRedirectToPage('SearchPage')} className={css.navLink}>
        <Icons name="navSearch" />
      </span>
      <span onClick={onRedirectToPage('LandingPage')} className={css.navLink}>
        <Icons name="navFavourites" />
      </span>
      <span onClick={onRedirectToPage('LandingPage')} className={css.homeIcon}>
        <Icons name="navHome" />
      </span>
      <span onClick={onRedirectToPage('LandingPage')} className={css.navLink}>
        <Icons name="navMessages" />
      </span>
      <span
        onClick={() => {
          if (isAuthenticated) {
            onRedirectToPage('AccountSettingsPage');
          } else {
            onOpenAuthModal(true);
          }
        }}
        className={css.navLink}
      >
        <Icons name="navAccount" />
      </span>
    </div>
  );
}

export default BottomNav;
