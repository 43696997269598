import australiaPost from '../assets/carrierIcons/australiaPost.png';
import canadaPost from '../assets/carrierIcons/canadaPost.png';
import dhl from '../assets/carrierIcons/dhl.png';
import fedex from '../assets/carrierIcons/fedex.png';
import ups from '../assets/carrierIcons/ups.png';
import usps from '../assets/carrierIcons/usps.png';
import uspsLogo from '../assets/usps-logo.ec465bb0e3d017c6055dd1aa86234e41.png';
import fedexLogo from '../assets/fedex-logo.svg';
import upsLogo from '../assets/ups-logo.28ef2757acc2c805a47f22be998e9222.svg';

// Navbar items
export const INSTRUMENTS = 'instruments';
export const ACCESSORIES = 'accessories';
export const BOOKSANDMEDIA = 'books-and-media';
export const WINEANDREED = 'wine-and-reed';
export const STRINGS = 'strings';
export const FLUTES = 'flutes';
export const PRECUSSION = 'percussion';
export const DOMESTIC = 'Domestic';
export const INTERNATIONAL = 'International';
export const ADD = 'add';
export const DISABLED_ADD_ICON = 'disabledAddIcon';
export const ADD_ICON = 'addIcon';
export const SUBTRACT = 'subtract';
export const DISABLED_SUBTRACT_ICON = 'disabledSubtractIcon';
export const PRICE = 'price';
export const SIZE = 'size';

export const FLAT_RATE = 'flat-rate';
export const CALCULATED = 'calculated';

export const PAYPAL_MERCHANT_PAGE = '/account/paypal-merchant';

const NAVBAR_ITEMS = {
  //Category
  INSTRUMENTS: INSTRUMENTS,
  ACCESSORIES: ACCESSORIES,
  BOOKSANDMEDIA: BOOKSANDMEDIA,

  //Level 1 Categories
  WINEANDREED: WINEANDREED,
  STRINGS: STRINGS,
  PRECUSSION: PRECUSSION,
};

// Freeze the object to prevent modification
export const ENUM_NAVBAR_ITEMS = Object.freeze(NAVBAR_ITEMS);

// Array of objects with key and label for each navbar item
export const NAVBAR_OPTIONS = [
  { key: ENUM_NAVBAR_ITEMS.INSTRUMENTS, label: 'Instruments' },
  { key: ENUM_NAVBAR_ITEMS.ACCESSORIES, label: 'Accessories', hideArrow: true },
  { key: ENUM_NAVBAR_ITEMS.BOOKSANDMEDIA, label: 'Books & Media', hideArrow: true },
];

export const NAVBAR_OPTIONS_INSTRUMENTS = [
  {
    key: ENUM_NAVBAR_ITEMS.WINEANDREED,
    label: 'Wind & Reed',
    parent: ENUM_NAVBAR_ITEMS.INSTRUMENTS,
  },
  { key: ENUM_NAVBAR_ITEMS.STRINGS, label: 'Strings', parent: ENUM_NAVBAR_ITEMS.INSTRUMENTS },
  { key: ENUM_NAVBAR_ITEMS.PRECUSSION, label: 'Percussion', parent: ENUM_NAVBAR_ITEMS.INSTRUMENTS },
];

//SHIPPING
export const MEDIAMAIL = 'mediamail';
export const LIBRARYMAIL = 'librarymail';
export const SMART_POST = 'smart_post';
export const EXPRESS = 'express';
export const STANDARD_OVERNIGHT = 'standard_overnight';
export const PRIORITY_OVERNIGHT = 'priority_overnight';
export const NEXT_DAY_AIR_EARLY_AM = 'nextdayairearlyam';
export const NEXT_DAY_AIR = 'nextdayair';
export const FEDEX_2_DAY = 'fedex2day';
export const FEDEX_2_DAY_AM = 'fedex2dayam';
export const SECOND_DAY_AIR = '2nddayair';
export const GROUND_ADVANTAGE = 'groundadvantage';
export const GROUND_HOME_DELIVERY = 'ground_home_delivery';
export const GROUND = 'ground';
export const FIRST_CLASS_MAIL_INTERNATIONAL = 'firstclassmailinternational';
export const FIRST_CLASS_PACKAGE_INTERNATIONAL_SERVICE = 'firstclasspackageinternationalservice';
export const PRIORITY_MAIL_INTERNATIONAL = 'prioritymailinternational';
export const EXPRESS_PLUS = 'expressplus';
export const EXPEDITED = 'expedited';
export const UPS_SAVER = 'upssaver';
export const FEDEX_INTERNATIONAL_CONNECT_PLUS = 'fedexinternationalconnectplus';
export const INTERNATIONAL_ECONOMY = 'internationaleconomy';
export const INTERNATIONAL_FIRST = 'internationalfirst';
export const INTERNATIONAL_PRIORITY = 'internationalpriority';
export const USPS_PRIORITY = 'priority';

export const ECONOMY_SERVICES = 'Economy services';
export const STANDARD_SERVICES = 'Standard services';
export const EXPEDITED_SERVICES = 'Expedited services';
export const ONE_DAY_SERVICE = 'One day service';
export const INTERNATIONAL_SERVICES = 'International services';
export const USPS = 'usps';
export const FEDEX = 'fedex';
export const UPS = 'ups';

export const SIGNUP = 'signup';
export const LOGIN = 'login';

// These tabs are being used in checkout page for TTL shipping
export const shippingDeliveryTabs = [
  {
    key: 'shippingDetails',
    label: 'Your Information',
  },
  {
    key: 'shippingMethod',
    label: 'Shipping Method',
  },
  {
    key: 'payment',
    label: 'Payment',
  },
];

// These tabs are being used in checkout page for pickup
export const pickupDeliveryTabs = [
  {
    key: 'payment',
    label: 'Payment',
  },
];

export const CHECKOUT_STEPS = {
  SHIPPING_DETAILS: 'shippingDetails',
  CARRIERS: 'carriers',
  PAYMENT_DETAILS: 'paymentDetails',
  STRIPE: 'stripe',
  WALLET: 'wallet',
  PAYPAL: 'paypal',
};

export const renderCarrierIcons = {
  USPS: usps,
  UPS: ups,
  FEDEX: fedex,
  DHL: dhl,
  DHLExpress: dhl,
  AUSTRALIAPOST: australiaPost,
  CANADAPOST: canadaPost,
};

export const signupInfoBlocks = [
  {
    iconName: 'cart',
    text: 'Browse unique logos generated just for you. No templates here.',
  },
  {
    iconName: 'cart',
    text: 'Browse unique logos generated just for you. No templates here.',
  },
  {
    iconName: 'cart',
    text: 'Browse unique logos generated just for you. No templates here.',
  },
];

export const carrierLogos = {
  [USPS]: uspsLogo,
  [FEDEX]: fedexLogo,
  [UPS]: upsLogo,
};
