import React from 'react';
import { string, func, bool } from 'prop-types';
import classNames from 'classnames';

import { useConfiguration } from '../../context/configurationContext';

import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import { displayPrice } from '../../util/configHelpers';
import { lazyLoadWithDimensions } from '../../util/uiHelpers';
import { propTypes } from '../../util/types';
import { formatMoney } from '../../util/currency';
import { ensureListing, ensureUser } from '../../util/data';
import { richText } from '../../util/richText';
import { createSlug } from '../../util/urlHelpers';
import { isBookingProcessAlias } from '../../transactions/transaction';

import {
  AspectRatioWrapper,
  AvatarSmall,
  Icons,
  NamedLink,
  ResponsiveImage,
} from '../../components';

import css from './ListingCard.module.css';
import { useDispatch, useSelector } from 'react-redux';
import { capitalizeFirstLetter, getWishlist, isArrayLength } from '../../util/genericHelpers';
import { selectCurrentUser } from '../../ducks/user.duck';
import { selectIsAuthenticated } from '../../ducks/auth.duck';
import routeConfiguration from '../../routing/routeConfiguration';
import { updateProfile } from '../../containers/ProfileSettingsPage/ProfileSettingsPage.duck';
import { getUserCart } from '../../util/dataExtractors';

const MIN_LENGTH_FOR_LONG_WORDS = 10;

const priceData = (price, currency, intl) => {
  if (price && price.currency === currency) {
    const formattedPrice = formatMoney(intl, price);
    return { formattedPrice, priceTitle: formattedPrice };
  } else if (price) {
    return {
      formattedPrice: intl.formatMessage(
        { id: 'ListingCard.unsupportedPrice' },
        { currency: price.currency }
      ),
      priceTitle: intl.formatMessage(
        { id: 'ListingCard.unsupportedPriceTitle' },
        { currency: price.currency }
      ),
    };
  }
  return {};
};

const LazyImage = lazyLoadWithDimensions(ResponsiveImage, { loadAfterInitialRendering: 3000 });

const PriceMaybe = props => {
  const { price, publicData, config, intl } = props;
  const { listingType } = publicData || {};
  const validListingTypes = config.listing.listingTypes;
  const foundListingTypeConfig = validListingTypes.find(conf => conf.listingType === listingType);
  const showPrice = displayPrice(foundListingTypeConfig);
  if (!showPrice && price) {
    return null;
  }

  const isBookable = isBookingProcessAlias(publicData?.transactionProcessAlias);
  const { formattedPrice, priceTitle } = priceData(price, config.currency, intl);
  return (
    <div className={css.price}>
      <div className={css.priceValue} title={priceTitle}>
        {formattedPrice}
      </div>
      {isBookable ? (
        <div className={css.perUnit}>
          <FormattedMessage id="ListingCard.perUnit" values={{ unitType: publicData?.unitType }} />
        </div>
      ) : null}
    </div>
  );
};

export const ListingCardComponent = props => {
  const config = useConfiguration();
  const {
    className,
    rootClassName,
    intl,
    listing,
    renderSizes,
    setActiveListing,
    showAuthorInfo,
    isSearchPage,
  } = props;

  const dispatch = useDispatch();

  const classes = classNames(
    rootClassName || css.root,
    className,
    isSearchPage ? css.searchPageListingCard : null
  );
  const currentListing = ensureListing(listing);
  const id = currentListing.id.uuid;
  const { title = '', price, publicData } = currentListing.attributes;
  const slug = createSlug(title);
  const author = ensureUser(listing.author);
  const authorName = author.attributes.profile.displayName;
  const firstImage =
    currentListing.images && currentListing.images.length > 0 ? currentListing.images[0] : null;

  const {
    aspectWidth = 1,
    aspectHeight = 1,
    variantPrefix = 'listing-card',
  } = config.layout.listingImage;
  const variants = firstImage
    ? Object.keys(firstImage?.attributes?.variants).filter(k => k.startsWith(variantPrefix))
    : [];

  const setActivePropsMaybe = setActiveListing
    ? {
        onMouseEnter: () => setActiveListing(currentListing.id),
        onMouseLeave: () => setActiveListing(null),
      }
    : null;

  const currentUser = useSelector(selectCurrentUser);
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const wishlist = getWishlist(currentUser);

  const cartItems = isAuthenticated ? getUserCart(currentUser) : null;

  const onUpdateProfile = params => {
    return dispatch(updateProfile(params));
  };

  const onUpdateCurrentUser = (array, isCart) => {
    const params = isCart
      ? { cart: Array.from(new Set(array)) }
      : { wishlist: Array.from(new Set(array)) };

    return onUpdateProfile({
      publicData: { ...params },
    });
  };

  return (
    <NamedLink className={classes} name="ListingPage" params={{ id, slug }}>
      <AspectRatioWrapper
        className={css.aspectRatioWrapper}
        width={aspectWidth}
        height={aspectHeight}
        {...setActivePropsMaybe}
      >
        <LazyImage
          rootClassName={css.rootForImage}
          alt={title}
          image={firstImage}
          variants={variants}
          sizes={renderSizes}
        />
        <div
          className={css.favoriteIcon}
          onClick={e => {
            e.preventDefault();
            e.stopPropagation();
            if (isAuthenticated) {
              const index = wishlist && wishlist.findIndex(b => b == id);
              if (id) {
                if (index > -1) {
                  wishlist && wishlist.splice(index, 1);
                  const removedwishlist = Array.from(new Set(wishlist));
                  onUpdateCurrentUser(removedwishlist);
                } else {
                  wishlist.push(id);
                  onUpdateCurrentUser(Array.from(new Set(wishlist)));
                }
              }
            } else {
              history.push(createResourceLocatorString('LoginPage', routeConfiguration(), {}, {}));
            }
          }}
        >
          {isArrayLength(wishlist) && wishlist.findIndex(e => e == id) > -1 ? (
            <Icons name="favorite" />
          ) : (
            <Icons name="unfavorite" />
          )}
        </div>
      </AspectRatioWrapper>
      <div className={css.info}>
        <div className={css.mainInfo}>
          <div className={css.title}>
            {richText(title, {
              longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS,
              longWordClass: css.longWord,
            })}
          </div>
          {showAuthorInfo && !isSearchPage ? (
            <div className={css.authorInfo}>
              <AvatarSmall user={author} className={css.providerAvatar} />
              <span className={css.soldby}>Sold by</span>
              <span className={css.authorName}>
                <FormattedMessage id="ListingCard.author" values={{ authorName }} />
              </span>
            </div>
          ) : null}
          {isSearchPage && (
            <div className={css.otherInfo}>
              {publicData?.condition !== 'new' ? 'Used -' : ''}{' '}
              {capitalizeFirstLetter(publicData?.condition)}
            </div>
          )}
          <PriceMaybe price={price} publicData={publicData} config={config} intl={intl} />
          {isSearchPage && (
            <div className={css.location}>
              <img
                src="https://cdn.britannica.com/33/4833-004-828A9A84/Flag-United-States-of-America.jpg"
                alt="flag"
              />{' '}
              House of Musical Traditions
            </div>
          )}
        </div>
        {/* {!isOwnListing ? ( */}
        {/* <div className={css.favorite}>
          <span
            className={css.bookmark}
            onClick={e => {
              if (isAuthenticated) {
                e.preventDefault();
                e.stopPropagation();
                if (id) {
                  const index = cartItems && cartItems.findIndex(b => b == id);
                  if (index > -1) {
                    cartItems && cartItems.splice(index, 1);
                    // setCartItems(cartItems);
                    onUpdateCurrentUser(cartItems, true, index);
                  } else {
                    cartItems.push(id);
                    // setCartItems(cartItems);
                    onUpdateCurrentUser(Array.from(new Set(cartItems)), true, index);
                  }
                }
              } else {
                //redirect
              }
            }}
          >
            {cartItems && cartItems.findIndex(e => e == id) > -1 ? 'Added' : 'Removed'}
          </span>
        </div> */}
        {/* ) : null} */}
      </div>
    </NamedLink>
  );
};

ListingCardComponent.defaultProps = {
  className: null,
  rootClassName: null,
  renderSizes: null,
  setActiveListing: null,
  showAuthorInfo: true,
};

ListingCardComponent.propTypes = {
  className: string,
  rootClassName: string,
  intl: intlShape.isRequired,
  listing: propTypes.listing.isRequired,
  showAuthorInfo: bool,

  // Responsive image sizes hint
  renderSizes: string,

  setActiveListing: func,
};

export default injectIntl(ListingCardComponent);
