import React from 'react';
import css from './FooterContainer.module.css';
import { Icons, NamedLink } from '../../components';

function FooterContainer() {
  return (
    <div className={css.footer}>
      <div className={css.fixedWidthContainer}>
        <div className={css.topContent}>
          <div className={css.leftSec}>
            <h2>Stay up to date!</h2>
            <p>Be the first to know about our latest happenings.</p>
            <div className={css.newsLetterSec}>
              <Icons name="email" />
              <input type="email" placeholder="Your Email" />
              <button className={css.subscribeBtn}>Subscribe</button>
            </div>
          </div>
          <div className={css.rightSec}>
            <div className={css.quickLinks}>
              <h2>Shop</h2>
              <NamedLink name="LoginPage">My account</NamedLink>
              <NamedLink name="LoginPage">Login</NamedLink>
              <NamedLink name="LoginPage">Wishlist</NamedLink>
              <NamedLink name="LoginPage">Cart</NamedLink>
            </div>
            <div className={css.quickLinks}>
              <h2>Information</h2>
              <NamedLink name="FAQPage">FAQ</NamedLink>
              <NamedLink name="LoginPage">Returns & Refunds</NamedLink>
            </div>
            <div className={css.quickLinks}>
              <h2>Company</h2>
              <NamedLink name="LoginPage">About</NamedLink>
              <NamedLink name="PrivacyPolicyPage">Privacy Policy</NamedLink>
              <NamedLink name="TermsOfServicePage">Terms & Conditions</NamedLink>
              <NamedLink name="LoginPage">Contact Support</NamedLink>
            </div>
          </div>
        </div>
        <div className={css.bottomContent}>
          <div className={css.socialMediaLinks}>
            <NamedLink name="LoginPage">
              <Icons name="facebook" />
            </NamedLink>
            <NamedLink name="LoginPage">
              <Icons name="instagram" />
            </NamedLink>
            <NamedLink name="LoginPage">
              <Icons name="twitter" />
            </NamedLink>
          </div>
          <div className={css.copyRight}>© 2023 Trad Market</div>

          <div className={css.countryCurrency}>
            <div className={css.languageSelect}>
              <span className={css.flag}>
                <img
                  src="https://img.freepik.com/free-vector/illustration-uk-flag_53876-18166.jpg?size=626&ext=jpg&ga=GA1.1.44546679.1699228800&semt=ais"
                  alt=""
                />
              </span>
              English <Icons name="downAngle" />
            </div>
            <div className={css.currencySelect}>
              USD <Icons name="downAngle" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FooterContainer;
