import React, { useState } from 'react';
import { Field } from 'react-final-form';
import Select, { components } from 'react-select';
import makeAnimated from 'react-select/animated';

import css from './FieldMultiSelect.module.css';
import classNames from 'classnames';

const customStyles = {
  control: styles => ({
    ...styles,
    backgroundColor: '#fff',
    border: 'solid 1px #EBEBEB',
    boxShadow: 'none',
    color: '#000',
    fontSize: '15px',
    fontWeight: '400',
    lineHeight: '100%',
    letterSpacing: '-0.04em',
    height: '50px',
    paddingLeft: '12px',
    paddingRight: '5px',
    minHeight: '50px',
    margin: '0px',
    fontWeight: '400',
    postion: 'relative',
    fontFamily: "'Inter', sans-serif;",
  }),

  dropdownIndicator: (base, state) => ({
    ...base,
    paddingRight: 0,
    transition: 'all .2s ease',
    fontFamily: "'Inter', sans-serif;",
    transform: state.isFocused.menuIsOpen ? 'rotate(180deg)' : null,
  }),
  indicatorSeparator: styles => ({ display: 'none' }),
  valueContainer: (base, state) => ({
    ...base,
    padding: 0,
    height: '50px',
    margin: 0,
    fontFamily: "'Inter', sans-serif;",
    fontSize: '16px',
    fontWeight: '400',
  }),
  input: (base, state) => ({
    ...base,
    padding: 0,
    height: '50px',
    margin: 0,
    color: '#000',
    fontFamily: "'Inter', sans-serif;",
    fontSize: '16px',
    fontWeight: '400',
  }),
  option: (styles, { isFocused, isDisabled, isSelected }) => ({
    ...styles,
    color: '#000',
    padding: '6px 10px',
    fontSize: '16px',
    fontWeight: '400',
    lineHeight: '100%',
    letterSpacing: '-0.04em',
    fontFamily: "'Inter', sans-serif;",
    backgroundColor: isDisabled ? null : isFocused ? '#F0EDF6' : isSelected ? '#FFF' : null,
  }),
  singleValue: (styles, { isFocused, isDisabled, isSelected }) => ({
    ...styles,
    color: '#4a4a4a',
    lineHeight: '118%',
    fontFamily: "'Inter', sans-serif;",
    fontSize: '16px',
    fontWeight: '400',
  }),
  menuPortal: base => ({ ...base, postion: 'absolute', top: '0', left: '0' }),
  menu: base => ({
    ...base,
    borderRadius: 0,
    marginTop: 0,
    backgroundColor: '#fff',
    color: '#000',
    fontFamily: "'Inter', sans-serif;",
  }),
  menuList: base => ({
    ...base,
    padding: '6px 10px',
    backgroundColor: '#fff',
    fontSize: '16px',
    fontWeight: '400',
    fontFamily: "'Inter', sans-serif;",

    lineHeight: '100%',
    letterSpacing: '-0.04em',
    color: '#000',
  }),
  placeholder: base => ({
    ...base,
    padding: '6px 10px',
    backgroundColor: '#fff',
    fontSize: '16px',
    fontWeight: '400',
    lineHeight: '100%',
    letterSpacing: '-0.04em',
    color: '#b2b2b2',
    fontFamily: "'Inter', sans-serif;",
  }),
  dropdownIndicator: base => ({
    ...base,
    color: '#000',
    fontFamily: "'Inter', sans-serif;",
  }),
};

const FieldMultiSelect = props => {
  const [selectedOptions, setSelectedOptions] = useState();
  if (typeof window === 'undefined') {
    return null;
  }

  const {
    name,
    options,
    label,
    className,
    closeMenuOnSelect,
    hideLabel = false,
    isMulti,
    customOnChange = () => {},
    placeholderText,
  } = props;

  const status = props.selectedOptions ? css.success : css.attention;

  const Option = props => (
    <div className={props.searchFld}>
      <components.Option {...props}>
        <input type="checkbox" checked={props.isSelected} onChange={() => null} />{' '}
        <label className={css.multiselectValueLabel}>{props.label}</label>
      </components.Option>
    </div>
  );

  const MultiValue = props => (
    <components.MultiValue {...props}>
      <span className={css.multiSelectLabel}>{props.data.label}</span>
    </components.MultiValue>
  );
  const renderSelect = typeof window !== 'undefined';

  return options && renderSelect ? (
    <Field name={name}>
      {props => {
        const { input } = props;
        const { onChange, ...rest } = input;
        return (
          <div className={className}>
            {label ? (
              <label
                className={classNames({
                  [css.hideLabel]: hideLabel,
                })}
              >
                {label}
              </label>
            ) : null}
            <div className={status}>
              <Select
                className={css.selectContainer}
                styles={customStyles}
                classNamePrefix="react-select"
                closeMenuOnSelect={closeMenuOnSelect}
                hideSelectedOptions={isMulti}
                placeholder={placeholderText}
                isMulti={isMulti}
                components={
                  ([Option, MultiValue],
                  {
                    IndicatorSeparator: () => null,
                  })
                }
                options={options}
                selected={selectedOptions}
                getOptionValue={value => value.value}
                {...rest}
                menuPosition="fixed"
                onChange={item => {
                  setSelectedOptions(item);
                  customOnChange(item);
                  input.onChange(item && item);
                }}
              />
            </div>
          </div>
        );
      }}
    </Field>
  ) : null;
};

export default FieldMultiSelect;
