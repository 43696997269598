import React, { Component, useEffect, useState } from 'react';
import { string } from 'prop-types';
import { intlShape, injectIntl } from '../../util/reactIntl';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import { ensureListing, ensureUser } from '../../util/data';
import { richText } from '../../util/richText';
import { createSlug } from '../../util/urlHelpers';
import { AspectRatioWrapper, NamedLink, ResponsiveImage, IconSpinner, Icons } from '..';
import { getUserCartDetails } from '../../util/dataExtractors';
import { useDispatch, useSelector } from 'react-redux';
import { selectCurrentUser } from '../../ducks/user.duck';
import { cartSelector } from '../../containers/CartPage/CartPage.duck';
import { selectIsAuthenticated } from '../../ducks/auth.duck';
import { lazyLoadWithDimensions } from '../../util/uiHelpers';
import trashIcon from './images/trash.png';

import css from './CartCard.module.css';
import { useMyContext } from '../../context/StateHolder';
import { removeItemFromCart } from '../../containers/CartPage/cartHelpers';
import { useConfiguration } from '../../context/configurationContext';
import { formatMoney } from '../../util/currency';
const { types } = require('sharetribe-flex-sdk');
const { Money } = types;

const MIN_LENGTH_FOR_LONG_WORDS = 10;

class ListingImage extends Component {
  render() {
    return <ResponsiveImage {...this.props} />;
  }
}
const LazyImage = lazyLoadWithDimensions(ListingImage, { loadAfterInitialRendering: 3000 });

export const CartCardComponent = props => {
  const { className, rootClassName, intl, listing, renderSizes, viewport, currentCartItem } = props;
  const config = useConfiguration();
  const currentUser = useSelector(selectCurrentUser);
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const [currentItem, setCurrentItem] = useState();

  const dispatch = useDispatch();
  const { removeItemFromCartError, removeItemFromCartInProgress } = useSelector(cartSelector);

  const [removeInProgress, setRemoveInProgress] = useState(false);
  const { setCartItems } = useMyContext();

  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureListing(listing);
  const id = currentListing.id.uuid;
  const { title = '', price, publicData } = currentListing.attributes;

  const slug = createSlug(title);
  const author = ensureUser(listing.author);

  let firstImage =
    currentListing.images && currentListing.images.length > 0 ? currentListing.images[0] : null;

  const variants = firstImage
    ? Object.keys(firstImage?.attributes?.variants).filter(k => k.startsWith('listing-card'))
    : [];

  const [isHovered, setHovered] = useState('');

  if (isHovered && currentListing && currentListing.images && currentListing.images.length > 1) {
    firstImage = currentListing.images[1];
  }

  const photos = publicData && publicData.photos;
  const firstPhoto = photos && photos.length && photos[0];

  const cartItems = getUserCartDetails(currentUser);

  const isMobileLayout = viewport && viewport.width > 0 && viewport.width < 580;

  useEffect(() => {
    setCurrentItem(currentCartItem?.item);
  }, [currentCartItem?.item?.stockCount]);
  const itemTotal =
    price && currentItem?.stockCount ? price.amount * currentItem?.stockCount : price.amount * 1;
  const formattedItemTotal = formatMoney(intl, new Money(itemTotal, config.currency));

  return (
    <div className={classes}>
      <div className={css.productDetails}>
        <div className={css.productDetailsLeft}>
          <div className={css.productImg}>
            <NamedLink className={classes} name="ListingPage" params={{ id, slug }}>
              <AspectRatioWrapper
                className={css.aspectRatioWrapper}
                width={1}
                height={1}
                onMouseOver={() => setHovered('hover')}
                onMouseOut={() => setHovered('')}
              >
                {firstPhoto ? (
                  <div className={css.firstPhoto}>
                    <img src={firstPhoto} alt="First Photo" />
                  </div>
                ) : (
                  <LazyImage
                    rootClassName={css.rootForImage}
                    alt={title}
                    image={firstImage}
                    variants={variants}
                    sizes={renderSizes}
                  />
                )}
              </AspectRatioWrapper>
            </NamedLink>
          </div>
          <div className={css.info}>
            <div className={css.mainInfo}>
              <NamedLink className={css.title} name="ListingPage" params={{ id, slug }}>
                {richText(title, {
                  longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS,
                  longWordClass: css.longWord,
                })}
              </NamedLink>
              <div className={css.authorInfo}>Condition</div>
              {isMobileLayout && (
                <div className={css.price}>
                  <div className={css.priceValue}>{formattedItemTotal}</div>
                  <div className={css.favorite}>
                    {removeInProgress ? (
                      <IconSpinner />
                    ) : cartItems && cartItems.findIndex(e => e == id) > -1 ? (
                      <span
                        className={css.bookmark}
                        onClick={() =>
                          removeItemFromCart({
                            isAuthenticated,
                            id,
                            dispatch,
                            cartItems,
                            config,
                          })
                        }
                      >
                        <Icons name="bin" /> Remove
                      </span>
                    ) : null}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        {!isMobileLayout && (
          <div className={css.price}>
            <div className={css.priceValue}>{formattedItemTotal}</div>
            <div className={css.favorite}>
              {removeInProgress ? (
                <IconSpinner />
              ) : cartItems && cartItems.findIndex(e => e == id) > -1 ? (
                <span
                  className={css.bookmark}
                  onClick={() =>
                    removeItemFromCart({
                      isAuthenticated,
                      id,
                      dispatch,
                      cartItems,
                      config,
                    })
                  }
                >
                  <img src={trashIcon} alt="Remove" /> Remove
                </span>
              ) : null}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

CartCardComponent.defaultProps = {
  className: null,
  rootClassName: null,
  renderSizes: null,
};

CartCardComponent.propTypes = {
  className: string,
  rootClassName: string,
  intl: intlShape.isRequired,
  listing: propTypes.listing.isRequired,
  // Responsive image sizes hint
  renderSizes: string,
};

export default injectIntl(CartCardComponent);
