import React, { useState } from 'react';
import NamedLink from '../../NamedLink/NamedLink';
import Icons from '../../Icons/Icons';
import { isArrayLength } from '../../../util/genericHelpers';

import { FLUTES, NAVBAR_OPTIONS_INSTRUMENTS, WINEANDREED } from '../../../util/enums';
import { CATEGORIES } from '../../../config/configGeneric';

import categoryImg from '../../../assets/1.png';

import css from './Navbar.module.css';
import classNames from 'classnames';

const Navbar = props => {
  const [subCategory, setSubCategory] = useState(WINEANDREED);
  const [subsubCategory, setSubSubCategory] = useState(FLUTES);

  const subcategories = CATEGORIES.find(category => category?.key === subCategory)?.subCategories;
  const subsubCategories =
    isArrayLength(subcategories) &&
    subcategories.find(sc => sc?.key === subsubCategory)?.subSubCategories;

  return (
    <div className={css.megaDropdownMenu}>
      <div className={css.navbarContainer}>
        {NAVBAR_OPTIONS_INSTRUMENTS.map((option, i) => (
          <div
            className={css.navbarCategories}
            key={i}
            onMouseEnter={() => setSubCategory(option.key)}
          >
            {option?.label}
          </div>
        ))}
      </div>
      <div className={css.subCategoriesWrapper}>
        <div className={css.verticalNavbar}>
          {isArrayLength(subcategories) &&
            subcategories.map((sc, i) => (
              <div
                className={classNames(css.verticalNavbarLink, {
                  // [css.activeNav]: sc?.key === subsubCategory,
                })}
                onMouseEnter={() => setSubSubCategory(sc.key)}
                key={i}
              >
                {sc?.label}
                <Icons name="rightAngle" />
              </div>
            ))}
        </div>
        <div className={css.verticalNavbarContent}>
          <div className={css.subcategories}>
            {isArrayLength(subsubCategories) &&
              subsubCategories.map((ssc, i) => (
                <NamedLink className={css.categoryLink} name="SearchPage" key={i}>
                  {ssc?.label}
                </NamedLink>
              ))}
          </div>
          <div className={css.categoryImg}>
            <img src={categoryImg} alt="Demo Category Image" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
