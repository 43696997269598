import { useCallback, useRef, useState } from 'react';
import { toast } from 'react-toastify';

/**
 * Checks if an array is valid (not null, undefined, or empty).
 * @param {array} arr - The array to check.
 * @returns {boolean} True if the array is valid, false otherwise.
 */
export const isArrayLength = arr => {
  // Check if the input parameter is an array and has a length greater than zero.
  return Array.isArray(arr) && (arr.length > 0 ?? false);
};

export const isWindowDefined = () => {
  return typeof window !== 'undefined';
};

export function isFunction(value) {
  return typeof value === 'function';
}

export const useStateRef = initialState => {
  const [state, setState] = useState(initialState);
  const ref = useRef(initialState);

  const dispatch = useCallback(stateToSet => {
    ref.current = isFunction(stateToSet) ? stateToSet(ref.current) : stateToSet;
    setState(ref.current);
  }, []);

  return [state, dispatch, ref];
};

export const renameObjKeyToValue = arr => {
  return arr.map(item => {
    const { key, ...rest } = item;
    return { value: key, ...rest };
  });
};

/**
 * Returns an array of wishlist items associated with a user's profile.
 * @param {object} currentUser - The user object.
 * @returns {array} An array of wishlist items, or an empty array if input is invalid or wishlist is missing.
 */
export const getWishlist = currentUser => {
  return (
    (currentUser && currentUser.id && currentUser?.attributes?.profile?.publicData?.wishlist) || []
  );
};

export const capitalizeFirstLetter = string => {
  if (!string) return '';
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const getLowerCaseName = string => {
  return string ? string.toLowerCase() : '';
};

export const cardRenderSizes = isMapVariant => {
  if (isMapVariant) {
    // Panel width relative to the viewport
    const panelMediumWidth = 50;
    const panelLargeWidth = 62.5;
    return [
      '(max-width: 767px) 100vw',
      `(max-width: 1023px) ${panelMediumWidth}vw`,
      `(max-width: 1920px) ${panelLargeWidth / 2}vw`,
      `${panelLargeWidth / 3}vw`,
    ].join(', ');
  } else {
    // Panel width relative to the viewport
    const panelMediumWidth = 50;
    const panelLargeWidth = 62.5;
    return [
      '(max-width: 549px) 100vw',
      '(max-width: 767px) 50vw',
      `(max-width: 1439px) 26vw`,
      `(max-width: 1920px) 18vw`,
      `14vw`,
    ].join(', ');
  }
};

export const groupListingsByAuthor = listings => {
  return listings.reduce((grouped, listing) => {
    const authorId = listing.author.id.uuid;
    if (!grouped[authorId]) {
      grouped[authorId] = [];
    }
    grouped[authorId].push(listing);
    return grouped;
  }, {});
};
export const sortCountriesWithPriority = countriesArray => {
  // Priority list of country codes
  const priorityCodes = ['US', 'GB'];

  // Splitting the array into priority and normal countries
  const priorityCountries = countriesArray.filter(country => priorityCodes.includes(country.code));
  const otherCountries = countriesArray.filter(country => !priorityCodes.includes(country.code));

  // Optional: Sort otherCountries alphabetically by label if needed
  otherCountries.sort((a, b) => a.label?.localeCompare(b.label));

  // Concatenating the priority countries with the sorted other countries
  return [...priorityCountries, ...otherCountries];
};

export const formatServiceName = serviceName => {
  // This function inserts a space before each capital letter in the serviceName string
  return serviceName
    .split('')
    .map((char, index) => {
      if (char === char.toUpperCase() && index !== 0) {
        return ' ' + char;
      }
      return char;
    })
    .join('');
};

export const getKeyFromLocalStorage = (key, type = 'string') => {
  const storedData =
    isWindowDefined() &&
    window.localStorage.getItem(key) &&
    typeof window.localStorage.getItem(key) === type &&
    JSON.parse(window.localStorage.getItem(key) || 'Tradmarket');
  return storedData;
};

export const setLocalStorageItems = (data, key) => {
  const uniqueData = Array.from(new Set(data));
  isWindowDefined() && window.localStorage.setItem(key, JSON.stringify(uniqueData));
  return uniqueData;
};

export const removeKeysFromLocalStorage = key => {
  isWindowDefined() && window.localStorage.removeItem(key);
};

/**
 * Display a toast notification based on the message type.
 *
 * @param {string} type The type of toast to display ('success' or 'error').
 * @param {string} message The message to display in the toast.
 * @param {Object} [options] Additional options for customizing the toast appearance and behavior.
 */
export const showToaster = (type, message, options = {}) => {
  const defaultOptions = {
    position: 'top-right',
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: 'light',
    ...options, // Merge any additional options passed to the function
  };

  // Display the toast based on the type
  if (type === 'success') {
    toast.success(message, defaultOptions);
  } else if (type === 'error') {
    toast.error(message, defaultOptions);
  } else {
    console.warn('showToast called with invalid type. Expected "success" or "error".');
  }
};

export const removeUnits = label => {
  return label.replace(/Units/gi, '').trim();
};

export const copyToClipboard = async text => {
  try {
    if (navigator.clipboard) {
      await navigator.clipboard.writeText(text);
      return;
    }

    const textArea = document.createElement('textarea');
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.focus({
      preventScroll: true,
    });
    textArea.select();
    document.execCommand('copy');
    document.body.removeChild(textArea);
  } catch (e) {
    console.error(`Copying failed with error: ${e}`);
    throw e;
  }
};

export const onSendEmail = (body = null) => {
  const isValidBody = typeof body === 'string';
  if (typeof window !== 'undefined') {
    const email = 'blayne@tradmarket.com';
    const encodedBody = isValidBody && encodeURIComponent(body);
    const messageWithBody = isValidBody ? `mailto:${email}?body=${encodedBody}` : `mailto:${email}`;
    window.location.href = messageWithBody;
  }
};

/**
 * Returns the pay in total amount of a transaction.
 * @param {Object} tx - The transaction object to retrieve the pay in total from.
 * @returns {number|undefined} The pay in total amount of the transaction, or undefined if it cannot be retrieved.
 */
export const getPayInTotal = tx => {
  return tx && tx.id && tx?.attributes?.payinTotal?.amount;
};

/**
 * Divides a value by 100. If the value is null or undefined, returns 0.
 * @param {number|null|undefined} value
 * @returns {number}
 */
export const divideAndHandleNull = value => {
  return value ? value?.amount / 100 : 0;
};

export const checkIfFreeShipping = value => {
  return isArrayLength(value) && Boolean(value);
};
