import { createAndVerifyAddress } from '../../../../util/api';
import {
  DOMESTIC,
  INTERNATIONAL,
  PRIORITY_MAIL_INTERNATIONAL,
  USPS_PRIORITY,
} from '../../../../util/enums';
import { getLowerCaseName, isArrayLength } from '../../../../util/genericHelpers';
import { requestUpdateListing } from '../../EditListingPage.duck';

export const onSaveCarrierModalContent = params => {
  const { onSubmit, setCarrierModalProps, groupedCarriers, dispatch, id, config } = params;
  try {
    const updatedValues = {
      publicData: {
        shippingCarriers: { ...groupedCarriers },
      },
    };
    if (Object.keys(groupedCarriers).length && id) {
      dispatch(requestUpdateListing({ ...updatedValues, id }, config));
    }
    setCarrierModalProps({ isCarrierModalOpen: false, carrierModalType: null });
  } catch (error) {}
};

export const getUSPSData = (data, type) => {
  if (!data || !type) {
    throw new Error('Invalid data or type provided'); // Improved error handling
  }

  const serviceLevelMap = {
    Domestic: USPS_PRIORITY,
    International: PRIORITY_MAIL_INTERNATIONAL,
  };

  const filteredServiceLevels = data.service_levels?.filter(
    serviceLevel => serviceLevel && serviceLevel.name.toLowerCase() === serviceLevelMap[type]
  );

  // Remove _params property
  filteredServiceLevels?.forEach(serviceLevel => delete serviceLevel._params);
  return filteredServiceLevels || []; // Return empty array if no matches
};
