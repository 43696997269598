import { getUSPSData } from '../containers/EditListingPage/EditListingWizard/EditListingDetailsPanel/editListingDetailsHelpers';
import {
  PayPalApi,
  createAndVerifyAddress,
  fetchCarriersAndRates,
  getShippingCarriers,
} from '../util/api';
import { DOMESTIC, INTERNATIONAL, USPS } from '../util/enums';
import { storableError } from '../util/errors';
import * as log from '../util/log';

// ================ Action types ================ //

export const FETCH_SHIPPING_CARRIERS_REQUEST = 'app/shipping/FETCH_SHIPPING_CARRIERS_REQUEST';
export const FETCH_SHIPPING_CARRIERS_SUCCESS = 'app/shipping/FETCH_SHIPPING_CARRIERS_SUCCESS';
export const FETCH_SHIPPING_CARRIERS_ERROR = 'app/shipping/FETCH_SHIPPING_CARRIERS_ERROR';
export const ADD_CARRIER = 'app/shipping/ADD_CARRIER';
export const DELETE_CARRIER = 'app/shipping/DELETE_CARRIER';
export const UPDATE_CARRIER = 'app/shipping/UPDATE_CARRIER';
export const SHIPPS_TO = 'app/shipping/SHIPPS_TO';
export const SET_INITIAL_CARRIERS = 'app/shipping/SET_INITIAL_CARRIERS';

export const GET_SHIPPING_CARRIERS_RATES_REQUEST =
  'app/shipping/GET_SHIPPING_CARRIERS_RATES_REQUEST';
export const GET_SHIPPING_CARRIERS_RATES_SUCCESS =
  'app/shipping/GET_SHIPPING_CARRIERS_RATES_SUCCESS';
export const GET_SHIPPING_CARRIERS_RATES_ERROR = 'app/shipping/GET_SHIPPING_CARRIERS_RATES_ERROR';

export const CREATE_ADDRESS_REQUEST = 'app/shipping/CREATE_ADDRESS_REQUEST';
export const CREATE_ADDRESS_SUCCESS = 'app/shipping/CREATE_ADDRESS_SUCCESS';
export const CREATE_ADDRESS_ERROR = 'app/shipping/CREATE_ADDRESS_ERROR';

// ================ Reducer ================ //

const initialState = {
  fetchCarriersInProgress: false,
  carriers: null,
  priorityCarriers: [],
  fetchCarrierError: null,
  domesticCarrier: [],
  internationalCarrier: [],
  carrierRates: [],
  groupedCarriers: {
    defaultDomesticCarrier: [],
    defaultInternationalCarrier: [],
    domesticAdditional: [],
    internationalAdditional: [],
  },
  getCarriersRatesInProgress: false,
  getCarriersRatesSuccess: false,
  getCarriersSelectorsRatesError: null,
  createAddressInProgress: false,
  createAddressSuccess: false,
  createAddressError: null,
  shippsTo: null,
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  const {
    newCarrier = [],
    carrierType = '',
    carrierId = '',
    currentCarrier = {},
    updatedCarrier = {},
    isAdditional,
  } = payload || {};
  const carrierKey = 'groupedCarriers';
  const updatedCarrierType = isAdditional
    ? `${carrierType.toLowerCase()}Additional`
    : `default${carrierType}Carrier`;
  const existingCarriers = state[carrierKey][updatedCarrierType] || [];

  switch (type) {
    case CREATE_ADDRESS_REQUEST:
      return { ...state, createAddressInProgress: true };
    case CREATE_ADDRESS_SUCCESS:
      return { ...state, createAddressInProgress: false, createAddressSuccess: true };
    case CREATE_ADDRESS_ERROR:
      return {
        ...state,
        createAddressError: true,
        createAddressSuccess: false,
        createAddressInProgress: false,
      };
    case GET_SHIPPING_CARRIERS_RATES_REQUEST:
      return { ...state, getCarriersRatesInProgress: true };
    case GET_SHIPPING_CARRIERS_RATES_SUCCESS:
      return {
        ...state,
        getCarriersRatesInProgress: false,
        carrierRates: payload,
        getCarriersRatesSuccess: true,
      };
    case GET_SHIPPING_CARRIERS_RATES_ERROR:
      return {
        ...state,
        getCarriersSelectorsRatesError: true,
        getCarriersRatesSuccess: false,
        getCarriersRatesInProgress: false,
      };
    case FETCH_SHIPPING_CARRIERS_REQUEST:
      return { ...state, fetchCarrierError: null, fetchCarriersInProgress: true };
    case FETCH_SHIPPING_CARRIERS_SUCCESS:
      // Filter the payload to include only USPS, UPS, and FedEx carriers
      const priorityCarriers = payload.filter(
        carrier =>
          carrier.name.toLowerCase().includes('usps') ||
          carrier.name.toLowerCase().includes('ups') ||
          carrier.name.toLowerCase().includes('fedex')
      );
      const uspsCarrier = priorityCarriers.find(carrier => carrier.name === USPS);
      const { _params, ...cleanedCarrier } = uspsCarrier;
      return {
        ...state,
        fetchCarriersInProgress: false,
        carriers: payload || [],
        priorityCarriers: priorityCarriers || [],
        groupedCarriers: {
          defaultInternationalCarrier: getUSPSData(cleanedCarrier, INTERNATIONAL),
          defaultDomesticCarrier: getUSPSData(cleanedCarrier, DOMESTIC),
        },
      };
    case FETCH_SHIPPING_CARRIERS_ERROR:
      return {
        ...state,
        fetchCarrierError: payload,
        fetchCarriersInProgress: false,
      };
    case UPDATE_CARRIER:
      // Ensure the carrier array exists before updating

      const newUpdatedCarriers = {
        ...state[carrierKey],
        [updatedCarrierType]: existingCarriers.map(carrier =>
          carrier.name === currentCarrier?.name ? updatedCarrier : carrier
        ),
      };

      return {
        ...state,
        [carrierKey]: newUpdatedCarriers,
      };

    case ADD_CARRIER:
      const updatedCarriers = {
        ...state[carrierKey],
        [updatedCarrierType]: [
          ...(state[carrierKey][updatedCarrierType] || []), // Ensure the array is initialized
          newCarrier,
        ],
      };

      return {
        ...state,
        [carrierKey]: updatedCarriers,
      };

    case DELETE_CARRIER:
      // Ensure the carrier array exists before filtering
      const filteredCarriers = {
        ...state[carrierKey],
        [updatedCarrierType]: existingCarriers.filter(carrier => carrier.name !== carrierId),
      };

      return {
        ...state,
        [carrierKey]: filteredCarriers,
      };
    case SET_INITIAL_CARRIERS:
      return {
        ...state,
        groupedCarriers: { ...payload },
      };
    default:
      return state;
  }
}

// ================ Selectors ================ //

export const getCarriersSelector = state => {
  const {
    carriers,
    fetchCarrierError,
    fetchCarriersInProgress,
    priorityCarriers,
    groupedCarriers,
    shipsTo,
    carrierRates,
  } = state.shipping;
  return {
    carriers,
    fetchCarrierError,
    fetchCarriersInProgress,
    priorityCarriers,
    groupedCarriers,
    shipsTo,
    carrierRates,
  };
};

// ================ Action creators ================ //

export const fetchShippingCarriersRequest = () => ({ type: FETCH_SHIPPING_CARRIERS_REQUEST });

export const fetchShippingCarriersSuccess = payload => ({
  type: FETCH_SHIPPING_CARRIERS_SUCCESS,
  payload,
});

export const fetchShippingCarriersError = e => ({
  type: FETCH_SHIPPING_CARRIERS_ERROR,
  payload: e,
  error: true,
});

export const getShippingCarriersRatesRequest = () => ({
  type: GET_SHIPPING_CARRIERS_RATES_REQUEST,
});

export const getShippingCarriersRatesSuccess = payload => ({
  type: GET_SHIPPING_CARRIERS_RATES_SUCCESS,
  payload,
});

export const getShippingCarriersRatesError = e => ({
  type: GET_SHIPPING_CARRIERS_RATES_ERROR,
  payload: e,
  error: true,
});

export const createAddressRequest = () => ({
  type: CREATE_ADDRESS_REQUEST,
});

export const createAddressSuccess = payload => ({
  type: CREATE_ADDRESS_SUCCESS,
  payload,
});

export const createAddressError = e => ({
  type: CREATE_ADDRESS_ERROR,
  payload: e,
  error: true,
});

const updateCarrierAction = ({ currentCarrier, updatedCarrier, carrierType, isAdditional }) => ({
  type: UPDATE_CARRIER,
  payload: { currentCarrier, updatedCarrier, carrierType, isAdditional },
});

const addCarrierAction = (newCarrier, carrierType, isAdditional) => ({
  type: ADD_CARRIER,
  payload: { newCarrier, carrierType, isAdditional },
});

const deleteCarrierAction = (carrierId, carrierType, isAdditional) => ({
  type: DELETE_CARRIER,
  payload: { carrierId, carrierType, isAdditional },
});
const addShipsToCarrierAction = data => ({
  type: SHIPPS_TO,
  payload: data,
});
export const setInitialCarriers = data => ({
  type: SET_INITIAL_CARRIERS,
  payload: data,
});

// ================ Selector ================ //

export const getShippingRatesProgress = state => {
  return {
    getCarriersSelectorsRatesError: state.shipping.getCarriersSelectorsRatesError,
    getCarriersRatesSuccess: state.shipping.getCarriersRatesSuccess,
    getCarriersRatesInProgress: state.shipping.getCarriersRatesInProgress,
    createAddressInProgress: state.shipping.getCarriersRatesInProgress,
  };
};

// ================ Thunks ================ //

export const fetchShippingCarriers = listing => async (dispatch, getState, sdk) => {
  dispatch(fetchShippingCarriersRequest());
  try {
    const { statusCode, data } = await getShippingCarriers();
    if (statusCode === 200) {
      dispatch(fetchShippingCarriersSuccess(data));
    }
    if (listing.id) {
      const { shippingCarriers = {} } = listing?.attributes?.publicData || {};
      dispatch(setInitialCarriers(shippingCarriers));
    }
  } catch (error) {
    dispatch(fetchShippingCarriersError(error));
  }
};
export const updateCarrier = ({ currentCarrier, updatedCarrier, carrierType, isAdditional }) => {
  return dispatch => {
    dispatch(updateCarrierAction({ currentCarrier, updatedCarrier, carrierType, isAdditional }));
  };
};

export const addCarrier = (newCarrier, carrierType, isAdditional) => {
  return dispatch => {
    dispatch(addCarrierAction(newCarrier, carrierType, isAdditional));
  };
};

export const deleteCarrier = (carrierId, carrierType, isAdditional) => {
  try {
    return dispatch => {
      dispatch(deleteCarrierAction(carrierId, carrierType, isAdditional));
    };
  } catch (error) {}
};
export const addShipsTo = data => {
  try {
    return dispatch => {
      dispatch(addShipsToCarrierAction(data));
    };
  } catch (error) {}
};

export const createAddress = params => async (dispatch, getState, sdk) => {
  dispatch(createAddressRequest());
  try {
    const response = await createAndVerifyAddress(params);
    dispatch(createAddressSuccess());
    return response?.data?.id; //Shipping address id
  } catch (error) {
    dispatch(createAddressError());
  }
};

export const getShippingCarriersAndRates = params => async (dispatch, getState, sdk) => {
  dispatch(getShippingCarriersRatesRequest());
  try {
    const response = await fetchCarriersAndRates(params);
    dispatch(getShippingCarriersRatesSuccess(response?.data));
    return response?.data; //rates
  } catch (error) {
    dispatch(getShippingCarriersRatesError());
  }
};
