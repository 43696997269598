import React, { useEffect } from 'react';
import { array, bool, func, number, object, shape, string } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { propTypes } from '../../util/types';
import { sendVerificationEmail, hasCurrentUserErrors } from '../../ducks/user.duck';
import { logout, authenticationInProgress, login, signup } from '../../ducks/auth.duck';
import { manageDisableScrolling } from '../../ducks/ui.duck';
import { Topbar } from '../../components';
import { useMyContextFunctions } from '../../context/ContextFunctions';
import { useMyContext } from '../../context/StateHolder';
import LoginForm from '../AuthenticationPage/LoginForm/LoginForm';
import SignupForm from '../AuthenticationPage/SignupForm/SignupForm';
import AuthModal from './AuthModal/AuthModal';
import { FormattedMessage } from '../../util/reactIntl';
import { isSignupEmailTakenError } from '../../util/errors';
import { LOGIN, SIGNUP } from '../../util/enums';

import css from './TopbarContainer.module.css';

export const TopbarContainerComponent = props => {
  const {
    authInProgress,
    currentPage,
    currentSearchParams,
    currentUser,
    currentUserHasListings,
    currentUserHasOrders,
    history,
    isAuthenticated,
    authScopes,
    hasGenericError,
    location,
    notificationCount,
    onLogout,
    onManageDisableScrolling,
    sendVerificationEmailInProgress,
    sendVerificationEmailError,
    onResendVerificationEmail,
    submitLogin,
    submitSignup,
    loginError,
    signupError,
    confirmError,
    ...rest
  } = props;

  const { onOpenAuthModal, onSetAuthType } = useMyContextFunctions();
  const { openAuthModal, authType } = useMyContext();

  useEffect(() => {
    if (signupError || loginError) {
      onOpenAuthModal(true);
    }
  }, [signupError, loginError]);

  // Unified function to handle the authentication modal state
  const handleAuthModalState = errorState => onOpenAuthModal(!!errorState);

  const handleSubmitSignup = async values => {
    const { fname, lname, ...rest } = values;
    const params = {
      firstName: fname.trim(),
      lastName: lname.trim(),
      ...rest,
    };
    onSetAuthType('signup');
    await submitSignup(params);

    // Use the unified function for modal state
    handleAuthModalState(signupError);
  };

  const handleSubmitLogin = async values => {
    await submitLogin(values);
    onSetAuthType('login');
    // Use the unified function for modal state
    handleAuthModalState(loginError);
  };

  const loginErrorMessage = (
    <div className={css.error}>
      <FormattedMessage id="AuthenticationPage.loginFailed" />
    </div>
  );

  const signupErrorMessage = (
    <div className={css.error}>
      {isSignupEmailTakenError(signupError) ? (
        <FormattedMessage id="AuthenticationPage.signupFailedEmailAlreadyTaken" />
      ) : (
        <FormattedMessage id="AuthenticationPage.signupFailed" />
      )}
    </div>
  );

  // eslint-disable-next-line no-confusing-arrow
  const errorMessage = (error, message) => (error ? message : null);
  const loginOrSignupError =
    authType === 'login'
      ? errorMessage(loginError, loginErrorMessage)
      : errorMessage(signupError, signupErrorMessage);

  const authModal = (
    <AuthModal
      id="AuthenticationModal"
      isOpen={openAuthModal}
      onClose={() => onOpenAuthModal(false)}
      onManageDisableScrolling={onManageDisableScrolling}
      usePortal
      isSignupModal={authType === SIGNUP}
    >
      {authType === LOGIN ? (
        <LoginForm
          className={css.loginForm}
          onSubmit={handleSubmitLogin}
          inProgress={authInProgress}
          renderLoginOrSignupError={loginOrSignupError}
        />
      ) : (
        <SignupForm
          className={css.signupForm}
          onSubmit={handleSubmitSignup}
          inProgress={authInProgress}
          renderLoginOrSignupError={loginOrSignupError}
        />
      )}
    </AuthModal>
  );

  return (
    <>
      <Topbar
        authInProgress={authInProgress}
        currentPage={currentPage}
        currentSearchParams={currentSearchParams}
        currentUser={currentUser}
        currentUserHasListings={currentUserHasListings}
        currentUserHasOrders={currentUserHasOrders}
        history={history}
        isAuthenticated={isAuthenticated}
        authScopes={authScopes}
        location={location}
        notificationCount={notificationCount}
        onLogout={onLogout}
        onManageDisableScrolling={onManageDisableScrolling}
        onResendVerificationEmail={onResendVerificationEmail}
        sendVerificationEmailInProgress={sendVerificationEmailInProgress}
        sendVerificationEmailError={sendVerificationEmailError}
        showGenericError={hasGenericError}
        {...rest}
      />
      {isAuthenticated ? null : authModal}
    </>
  );
};

TopbarContainerComponent.defaultProps = {
  currentPage: null,
  currentSearchParams: null,
  currentUser: null,
  currentUserHasOrders: null,
  notificationCount: 0,
  sendVerificationEmailError: null,
  authScopes: null,
};

TopbarContainerComponent.propTypes = {
  authInProgress: bool.isRequired,
  currentPage: string,
  currentSearchParams: object,
  currentUser: propTypes.currentUser,
  currentUserHasListings: bool.isRequired,
  currentUserHasOrders: bool,
  isAuthenticated: bool.isRequired,
  authScopes: array,
  notificationCount: number,
  onLogout: func.isRequired,
  onManageDisableScrolling: func.isRequired,
  sendVerificationEmailInProgress: bool.isRequired,
  sendVerificationEmailError: propTypes.error,
  onResendVerificationEmail: func.isRequired,
  hasGenericError: bool.isRequired,

  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
  location: shape({ state: object }).isRequired,
};

const mapStateToProps = state => {
  // Topbar needs isAuthenticated
  const {
    isAuthenticated,
    loginError,
    signupError,
    logoutError,
    confirmError,
    authScopes,
  } = state.auth;

  // Topbar needs user info.
  const {
    currentUser,
    currentUserHasListings,
    currentUserHasOrders,
    currentUserNotificationCount: notificationCount,
    sendVerificationEmailInProgress,
    sendVerificationEmailError,
  } = state.user;
  const hasGenericError = !!(logoutError || hasCurrentUserErrors(state));
  return {
    authInProgress: authenticationInProgress(state),
    currentUser,
    currentUserHasListings,
    currentUserHasOrders,
    notificationCount,
    isAuthenticated,
    authScopes,
    loginError,
    signupError,
    confirmError,
    sendVerificationEmailInProgress,
    sendVerificationEmailError,
    hasGenericError,
  };
};

const mapDispatchToProps = dispatch => ({
  onLogout: historyPush => dispatch(logout(historyPush)),
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
  onResendVerificationEmail: () => dispatch(sendVerificationEmail()),
  submitLogin: ({ email, password }) => dispatch(login(email, password)),
  submitSignup: params => dispatch(signup(params)),
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const TopbarContainer = compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(TopbarContainerComponent);

export default TopbarContainer;
